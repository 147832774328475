import { OPTIMUS } from '@/assets/js/api.js'
import axios from 'axios'

const { APIURL } = OPTIMUS
const ENDPOINT = 'getModelByID/'

// Generic
export function postGetModelByID(Bucket, Key) {
  return axios({
    method: 'post',
    url: APIURL + ENDPOINT,
    data: {
      Bucket,
      Key
    }
  })
}
