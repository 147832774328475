import { colorList } from '@/assets/js/library'
import { scaleQuantile } from 'd3-scale'
/**
 * @import chroma color library
 */
import chroma from 'chroma-js'

import {
  AmbientLight,
  DirectionalLight,
  Mesh,
  MeshBasicMaterial,
  MeshStandardMaterial,
  Color
} from 'three'
// checks to see if material has already been created
// if it has not already been created, create a new material
// else use material by color lookup dictonary
// using MeshBasicMaterial to save on memory
// does not calculate light bounces for better performance
export function colorMesh(
  obj,
  colorRamp,
  defaultMaterial,
  materialType,
  zeroScale,
  opacity,
  binType
) {
  const mats = {
    MeshBasicMaterial: MeshBasicMaterial,
    MeshStandardMaterial: MeshStandardMaterial
  }

  const checkColorMaterialList = (color, materialType, colorList) => {
    const colorKeys = Object.keys(colorList[materialType]['colors'])
    // check to see if color exists in dictionary
    if (colorKeys.includes(color) === false) {
      // check for opacity in the settings.json per metric
      colorList[materialType]['options']['opacity'] =
        opacity !== undefined ? opacity : 1
      colorList[materialType]['options']['transparent'] = opacity !== undefined

      colorList[materialType]['colors'][color] = new mats[materialType](
        colorList[materialType]['options']
      )

      colorList[materialType]['colors'][color].color = new Color(color)
    }
    return colorList[materialType]['colors'][color]
  }

  obj.traverse(function(child) {
    if (child instanceof Mesh) {
      if (child.userData) {
        const value = parseFloat(child.userData['customAttribute'])

        let color

        if (binType === 'Linear') {
          color =
            value <= 0 && zeroScale === true
              ? '#4D4D4D'
              : colorRamp(value).hex()
        } else {
          color =
            value <= 0 && zeroScale === true ? '#4D4D4D' : colorRamp(value)
        }

        child.material = checkColorMaterialList(color, materialType, colorList)
        child.receiveShadow = false

        if (child.name === 'buildings') {
          child.castShadow = true
        } else {
          child.castShadow = false
        }
      } else {
        child.material = defaultMaterial
      }
    }
  })
  return Object.assign(obj, {
    _isVue: true
  })
}

/**
 * @param {String} binType type of bin Quantile or Linear
 * @param {Array} binBounds array of numerical bounds
 * @param {Array} colorRamp array of string hex codes
 * @param {Array} linearBounds array of the lower and upper bound of the metric
 * @returns colorScale object for legend and metric coloring
 */
export function assignColorBinning(
  binType,
  binBounds,
  colorRamp,
  linearBounds
) {
  let scale = chroma.scale(colorRamp).domain(linearBounds)
  if (binType === 'Quantile') {
    scale = scaleQuantile()
      .domain(binBounds)
      .range(colorRamp)
  }

  return scale
}
/**
 * @param {String} binType type of bin Quantile or Linear
 * @param {Array} binBounds array of numerical bounds
 * @param {Array} colorRamp array of string hex codes
 * @param {*} colorScale chroma.js color scale
 * @returns {Array} of objects {color: hex-code, width: px}
 */
export function createMetricLegend(binType, binBounds, binColors, colorScale) {
  const arr = []
  const maxWidth = 350
  if (binType === 'Quantile') {
    const minWidth = maxWidth / binBounds.length

    binColors.forEach(d => {
      arr.push({
        color: d,
        width: minWidth
      })
    })
  } else if (binType === 'Linear') {
    for (let i = 0; i < maxWidth; i++) {
      arr.push({
        color: colorScale(i).hex(),
        width: 1
      })
    }
  }
  return arr
}

export class Lights {
  constructor() {
    this.ambient = new AmbientLight(0xe8ecff, 1.25)
    this.ambient.name = 'ambientLight'
    this.directionalLight = new DirectionalLight(0xfff1f1, 0.7)
    this.directionalLight2 = new DirectionalLight(0x8fbfb9, 0.4)
    // this.shadowCameraHelper = new cameraHelper(this.directionalLight.shadow.camera)
    // this.directionalLightHelper = new directionalLight(0x87c0ff, 0.2)
  }

  createDirectionalLight() {
    this.directionalLight.name = 'directionalLight'
    this.directionalLight.position.set(900, 300, -500)
    this.directionalLight.castShadow = true

    this.directionalLight.shadow.camera.right = 2500
    this.directionalLight.shadow.camera.left = -2500
    this.directionalLight.shadow.camera.top = 2500
    this.directionalLight.shadow.camera.bottom = -2500
    this.directionalLight.shadow.camera.near = 0
    this.directionalLight.shadow.camera.far = 5000
    this.directionalLight.shadow.bias = -0.002

    this.directionalLight2.name = 'directionalLight2'
    this.directionalLight2.position.set(-600, 1200, -500)
    this.directionalLight2.castShadow = false

    // this.directionalLight2.shadow.camera.right = 2500
    // this.directionalLight2.shadow.camera.left = -2500
    // this.directionalLight2.shadow.camera.top = 2500
    // this.directionalLight2.shadow.camera.bottom = -2500
    // this.directionalLight2.shadow.camera.near = 0
    // this.directionalLight2.shadow.camera.far = 5000

    // this.shadowCameraHelper.visible = false
    // this.shadowCameraHelper.name = 'shadowCameraHelper'
  }

  // createDirectionalLightHelper(directionalLight){
  //   this.directionalLightHelper.name = 'directionalLightHelper'
  //   this.directionalLightHelper.position.set(1, 1, -1)
  // }
}

export class ConstructScene {
  constructor(Scene, sceneColor, sceneFog) {
    this.scene = new Scene()
    this.scene.name = 'main scene'
    this.scene.background = sceneColor
    this.scene.fog = sceneFog

    this.camera = null
    this.renderer = null
    this.controls = null
  }

  constructCamera(PerspectiveCamera, CanvasHeight) {
    this.camera = new PerspectiveCamera(
      45,
      window.innerWidth / (window.innerHeight - CanvasHeight),
      10,
      15000
    )
    this.camera.position.set(0, 0, 0)

    return this
  }

  constructControls(OrbitControls, domElement) {
    this.controls = new OrbitControls(this.camera, domElement)
    this.controls.target.set(0, 0, 0)
    this.controls.maxDistance = 6000
    this.controls.autoRotate = false
    this.controls.autoRotateSpeed = 0.05
    this.controls.enableDamping = true
    this.controls.dampingFactor = 0.4
    this.controls.maxPolarAngle = Math.PI / 2 - 0.15

    return this
  }

  constructRenderer(WebGlRenderer, CanvasHeight, PCFSoftShadowMap) {
    this.renderer = new WebGlRenderer({
      antialias: true,
      powerPreference: 'high-performance',
      preserveDrawingBuffer: true
    })
    this.renderer.setPixelRatio(window.devicePixelRatio)
    this.renderer.setSize(window.innerWidth, window.innerHeight - CanvasHeight)
    this.renderer.shadowMap.enabled = true
    // this.renderer.gammaInput = true;
    // this.renderer.gammaOutput = true;
    // this.renderer.physicallyCorrectLights = true;
    // this.renderer.outputEncoding = THREE.sRGBEncoding;
    this.renderer.shadowMap.type = PCFSoftShadowMap
    return this
  }
}

export class MovingAverageCalculator {
  constructor() {
    this.count = 0
    this._mean = 0
  }

  update(newValue) {
    this.count++

    const differential = (newValue - this._mean) / this.count

    const newMean = this._mean + differential

    this._mean = newMean
  }

  get mean() {
    this.validate()
    return this._mean
  }

  validate() {
    if (this.count === 0) {
      throw new Error('Mean is undefined')
    }
  }
}

export function getProjectPathCookie() {
  let projectCookie = ''
  document.cookie.split(';').some(item => {
    if (item.trim().startsWith('projectPath=')) {
      projectCookie = item.split('=')[1]
    }
  })
  return projectCookie
}

export function disposeScene(scene, renderer) {
  scene.traverse(object => {
    if (!object.isMesh) return

    object.children.forEach(child => {
      child.geometry.dispose()
      child.material.dispose()
      child.texture.dispose()
    })

    scene.remove(object)
  })

  renderer.dispose()
  renderer.renderLists.dispose()
  renderer.forceContextLoss()

  renderer.domElement.addEventListener('dblclick', null, false)
  renderer.domElement = undefined
  renderer.clear()
}

export function cleanScenes(scenes) {
  const cleanMaterial = material => {
    material.dispose()

    // dispose textures
    for (const key of Object.keys(material)) {
      const value = material[key]
      if (value && typeof value === 'object' && 'minFilter' in value) {
        value.dispose()
      }
    }
  }

  scenes.forEach(scene => {
    scene.traverse(object => {
      if (!object.isMesh) return

      object.children.forEach(child => {
        child.geometry.dispose()
        child.material.dispose()
      })

      if (object.material.isMaterial) {
        cleanMaterial(object.material)
      } else {
        // an array of materials
        for (const material of object.material) cleanMaterial(material)
      }
      scene.remove(object)
      object = undefined
    })

    scene = undefined
  })

  scenes = null
  scenes = []
}
/**
 *
 * @param { Object } selectedModel the selected model
 * @param { Object } metrics the metricObject containing metric settings
 * @return { Object }
 */
export function getStarRating(selectedModel, metrics) {
  // const metricObject = Object.assign({}, metrics)
  const metricObject = JSON.parse(JSON.stringify(metrics))

  for (let key in metrics) {
    let value = metrics[key].scaleQuantile(selectedModel[key])
    metricObject[key].value = selectedModel[key]
    metricObject[key].prettyValue = Math.round(10 * selectedModel[key]) / 10
    // local array to fill
    // expected output will be strings
    let starArray = ['', '', '', '', '']
    // assign star rating to metric object
    metricObject[key].starRating = value
    // boolean if value is integer
    let valueInteger = Number.isInteger(value)
    // floor value
    let v = Math.floor(value)
    // fills the array based on flooring of value
    if (valueInteger) {
      starArray.fill('full', 0, v)
      starArray.fill('empty', v, 5)
    } else if (!valueInteger) {
      starArray.fill('full', 0, v)
      starArray.splice(v, 0, 'half')
      starArray.fill('empty', v + 1, 5)
    }
    // since array needs to be populated with 5 places
    // for array.fill to work, pop off last value
    if (starArray.length >= 6) {
      starArray.pop()
    }
    // assign star array to metric object
    metricObject[key].starRatingArray = starArray

    metricObject[key].benchmark = !(
      +metricObject[key].value < metricObject[key].calcAvgClass.mean
    )
  }
  return metricObject
}
/**
 * @param {array} modelData
 * @param {array} keys
 * @param {array} values
 * @return {string} ID filtered by given values
 */
export function filterModelData(modelData, keys, values) {
  return modelData.filter(d => {
    return keys.every(j => {
      return d[j] === values[j]
    })
  })
}

export function getRandomIterationID(numberOfPossible) {
  return Math.floor(Math.random() * numberOfPossible.length - 1)
}

export function filterHeaders(headerArray, filterBy) {
  return headerArray.filter(d => {
    return d.split('_')[0] === filterBy
  })
}

export function arrayToObject(array, keyField) {
  return array.reduce((obj, item) => {
    obj[item[keyField]] = item
    return obj
  }, {})
}

export function captureScreen(canvas) {
  return canvas.toDataURL('image/png')
}
