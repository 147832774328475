<template>
  <nav id="navbar" class="navbar navbar-dark active-pointer-item container">
    <b-navbar-nav class="justify-content-end d-flex flex-row">
      <scoutLogoWhite id="scoutLogoWhite" />
      <div class="d-flex align-items-end sublabel ml-2">v {{ appVersion }}</div>
    </b-navbar-nav>

    <b-navbar-nav class="justify-content-end flex-row">
      <b-nav-item
        :ref="navItem.name"
        :id="navItem.name"
        :class="{ 'active-route': currentRoute === navItem.name }"
        href="#"
        v-for="navItem in navLinks"
        :key="navItem.name"
        @click="routeChange(navItem.path)"
        >{{ navItem.name }}
        <sup v-if="navItem.name === 'Collection'"
          >[{{ collectionModelCount }}]</sup
        >
        <sup v-if="navItem.name === 'Narrative'">[{{ narrativeLength }}]</sup>
      </b-nav-item>
    </b-navbar-nav>
  </nav>
</template>
<script>
import scoutLogoWhite from '@/assets/icons/scout_logo_white.svg'
/**
 * @vue-import store mapping store to view
 */
import { mapGetters } from 'vuex'

import narrativeObject from '@/assets/js/narrative.js'

export default {
  components: {
    scoutLogoWhite
  },
  data() {
    return {
      narrativeObject: narrativeObject['exploreMode']
    }
  },
  computed: {
    narrativeLength() {
      return this.narrativeObject.length
    },
    currentRoute() {
      return this.$route.name === null ? 'Explore' : this.$route.name
    },
    ...mapGetters({
      collectionModelCount: 'getCollectionModelCount',
      narrativeMode: 'getNarrativeMode',
      appVersion: 'getAppVersion',
      mobileMode: 'getMobileMode',
      scoutMode: 'getScoutMode'
    }),
    /**
     * @output
     * returns current route
     */
    navLinks() {
      let mobile = this.mobileMode
      let narrativeMode = this.narrativeMode
      let options = this.$router.options.routes.filter(d => d.link === true)

      // checks if mobile friendly
      if (mobile) {
        options = options.filter(d => d.mobile === true)
      }
      // checks if narrative mode is on, if yes,
      // then make sure link should be active
      if (narrativeMode) {
        options = options.filter(d => d.presentationMode === true)
      }
      // checks if scout is in public mode
      if (this.scoutMode === 'public') {
        options = options.filter(d => d.publicMode === true)
      }

      return options
    }
  },
  methods: {
    routeChange: function (route) {
      this.$router.push(route)
    }
  }
}
</script>
<style lang="scss">
#scoutLogoWhite {
  width: auto;
  height: 20px;

  fill: $white;
}

.nav-item {
  font-size: $font-md;
  font-weight: $font-light;
  line-height: 2.25;
  letter-spacing: 1px;
  margin: 0 0.5rem;

  &.active-route {
    border-bottom: 1px solid $highlight-color;
  }
  .nav-link {
    color: $white !important;
    padding: 0 !important;
    transition: color $short-transition;

    &:hover {
      color: $highlight-color !important;
    }
  }
}
.navbar {
  // position: absolute!important;
  top: 0;
  max-width: 100% !important;

  z-index: 10;

  background-color: $black;
  color: $white;

  pointer-events: none;

  height: $navbar-height;
}
</style>
