<template>
  <div
    class="loading-container d-flex-content-center top-index-ui"
    v-if="loadingFlag === true"
  >
    <!-- <div class="loading-counter">{{percentComplete}}% Loaded</div> -->
    <div class="loading-counter">Loading</div>
    <div class="boxes">
      <div class="box">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      <div class="box">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      <div class="box">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      <div class="box">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'loading',
  props: {
    loadingFlag: Boolean
    // percentComplete: Number
  }
}
</script>

<style lang="scss" scoped>
// loading animation
.loading-container {
  position: absolute;

  top: 0;
  left: 0;

  height: 100vh;
  width: 100vw;

  color: white;

  pointer-events: all;

  // background-color: $black;

  .loading-counter {
    position: absolute;

    margin-top: 175px;
  }
}
.boxes {
  // height: 33vh;
  // width: 33vw;
  display: flex;
  // position: relative;
  // -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  // -webkit-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
  // margin-top: 32px;
  // -webkit-transform: rotateX(60deg) rotateZ(45deg) rotateY(0deg) translateZ(0px);
  transform: rotateX(60deg) rotateZ(45deg) rotateY(0deg) translateZ(0px);

  // margin: 2rem;
}
.boxes .box {
  width: 32px;
  height: 32px;
  top: 0px;
  left: 0;
  position: absolute;
  // -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.boxes .box:nth-child(1) {
  -webkit-transform: translate(100%, 0);
  transform: translate(100%, 0);
  -webkit-animation: box1 1s linear infinite;
  animation: box1 1s linear infinite;
}
.boxes .box:nth-child(2) {
  -webkit-transform: translate(0, 100%);
  transform: translate(0, 100%);
  -webkit-animation: box2 1s linear infinite;
  animation: box2 1s linear infinite;
}
.boxes .box:nth-child(3) {
  -webkit-transform: translate(100%, 100%);
  transform: translate(100%, 100%);
  -webkit-animation: box3 1s linear infinite;
  animation: box3 1s linear infinite;
}
.boxes .box:nth-child(4) {
  -webkit-transform: translate(200%, 0);
  transform: translate(200%, 0);
  -webkit-animation: box4 1s linear infinite;
  animation: box4 1s linear infinite;
}
$light-face: #fff;
$dark-face: rgb(250, 250, 250);
$darkest-face: rgb(245, 245, 245);

.boxes .box > div {
  // background: $light-face;
  background: transparent;
  border: 1px solid $light-face;
  --translateZ: 15.5px;
  --rotateY: 0deg;
  --rotateX: 0deg;
  position: absolute;
  width: 100%;
  height: 100%;
  // background: $light-face;
  top: auto;
  right: auto;
  bottom: auto;
  left: auto;
  -webkit-transform: rotateY(var(--rotateY)) rotateX(var(--rotateX))
    translateZ(var(--translateZ));
  transform: rotateY(var(--rotateY)) rotateX(var(--rotateX))
    translateZ(var(--translateZ));
}

.boxes .box > div:nth-child(1) {
  top: 0;
  left: 0;
  // background: $light-face;
  background: transparent;
  border: 1px solid $light-face;
}
.boxes .box > div:nth-child(2) {
  // background: $darkest-face;
  background: transparent;
  border: 1px solid $darkest-face;
  right: 0;
  --rotateY: 90deg;
}
.boxes .box > div:nth-child(3) {
  // background: $dark-face;
  border: 1px solid $dark-face;
  --rotateX: -90deg;
}

@keyframes box1 {
  0%,
  50% {
    transform: translate(100%, 0);
  }
  100% {
    transform: translate(200%, 0);
  }
}

@keyframes box2 {
  0% {
    transform: translate(0, 100%);
  }
  50% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(100%, 0);
  }
}

@keyframes box3 {
  0%,
  50% {
    transform: translate(100%, 100%);
  }
  100% {
    transform: translate(0, 100%);
  }
}

@keyframes box4 {
  0% {
    transform: translate(200%, 0);
  }
  50% {
    transform: translate(200%, 100%);
  }
  100% {
    transform: translate(100%, 100%);
  }
}
</style>
