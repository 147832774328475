<template>
  <div
    id="narrativeCard"
    class="notification-ui frosted-glass d-flex"
    :class="{ open: narrativePanelOpen, closed: !narrativePanelOpen }"
    v-if="narrativeFlag"
  >
    <div
      class="exit-panel d-flex align-items-center justify-content-center"
      @click="narrativePanelOpen = true"
      title="close panel"
      v-if="narrativePanelOpen === false"
    >
      <div class="">+</div>
    </div>
    <div id="narrativeCardPanel" class="d-flex flex-column flex-grow-1">
      <div
        id="exitOpenButton"
        class="exit-button-layout right m-1"
        @click="narrativePanelOpen = false"
      >
        x
      </div>
      <div class="mt-2 p-2">
        <h3>add to narrative</h3>
      </div>
      <b-form-input
        placeholder="Enter Title"
        v-model="title"
        type="text"
        key="narrative-title"
      ></b-form-input>

      <b-form-textarea
        id="textarea"
        v-model="description"
        placeholder="Enter Description"
        rows="3"
        key="narrative-description"
        no-resize
      ></b-form-textarea>
      <div class="mt-2 p-2 d-flex-content-center">
        <b-btn variant="primary" class="mr-2" @click="submit">submit</b-btn>
        <b-btn variant="outline-secondary" @click="reset">reset</b-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import narrativeObject from '@/assets/js/narrative.js'
import { exploreModeSettings } from '@/assets/js/library.js'
import { captureScreen } from '@/assets/js/helper.js'

export default {
  name: 'narrativeCard',
  props: {
    createNarrative: Boolean
  },
  components: {},
  data() {
    return {
      title: '',
      description: '',
      narrativePanelOpen: true
    }
  },
  watch: {
    narrativeFlag(newValue) {
      if (newValue === false) {
        this.reset()
      }
    }
  },
  computed: {
    ...mapGetters({
      narrativeFlag: 'getNarrativeFlag',
      modelID: 'getModelID',
      metricSelected: 'getMetricSelected',
      explorePanel: 'getExplorePanel'
    })
  },
  methods: {
    submit() {
      switch (this.$route.name) {
        case 'Explore':
          const metric =
            this.metricSelected === null || this.metricSelected.status === false
              ? {}
              : this.metricSelected

          const cameraPosition = Object.assign(
            {},
            exploreModeSettings['camera']['position']
          )

          const exploreObj = {
            route: this.$route.name,
            title: this.title,
            description: this.description,
            modelID: this.modelID,
            metric: Object.assign({}, metric),
            cameraPosition: cameraPosition,
            image: captureScreen(document.querySelector('canvas')),
            explorePanel: this.explorePanel['Controls'].active
          }

          narrativeObject['exploreMode'].push(exploreObj)

          this.reset()

          break
      }
    },
    reset() {
      this.title = ''
      this.description = ''
    }
  }
}
</script>
<style lang="scss">
$card-width: 325px;
#narrativeCard {
  position: absolute;

  width: $card-width;

  bottom: $navbar-height;

  border-radius: 2px;

  border: 1px solid $off-white;

  transition: all $short-transition;

  &.open {
    right: $navbar-height;
  }
  &.closed {
    right: calc(-#{$card-width} + 50px);
  }

  .exit-panel {
    width: 50px;
    background: $blue;
    cursor: pointer;

    &:hover {
      background: white;
      color: $blue;
    }
  }
  input,
  textarea {
    color: $off-white;
    background: none !important;
    border-radius: 0px;
  }
}
</style>
