<template>
  <div id="app">
    <navbar />
    <router-view />
    <loading :loadingFlag="loadingFlag" />
    <notification />
    <narrativeCard />
    <presentationCard />
    <presentationLandingCard />
  </div>
</template>
<script>
import navbar from '@/components/Utility/navbar.vue'
import { mapGetters } from 'vuex'
/**
 * loading container
 */
import loading from '@/components/Utility/loading.vue'
import notification from '@/components/Utility/notification.vue'
import narrativeCard from '@/components/narrative/narrative_card.vue'
import presentationCard from '@/components/narrative/presentation_card.vue'
import presentationLandingCard from '@/components/narrative/presentation_landing_card.vue'

export default {
  name: 'App',
  components: {
    navbar,
    loading,
    narrativeCard,
    notification,
    presentationLandingCard,
    presentationCard
  },
  computed: {
    ...mapGetters({
      loadingFlag: 'getLoadingFlag'
    })
  },
  created() {
    this.testIsMobile()
    window.addEventListener('resize', this.testIsMobile)
  },
  methods: {
    /**
     * @output
     * returns if the screen is a mobile devive as true or false
     */
    testIsMobile: function (e) {
      this.$store.commit('setMobileMode', window.innerWidth < 768)
    }
  }
}
</script>
<style lang="scss">
@import url('https://fonts.googleapis.com/css?family=Roboto+Mono|Roboto:300,400,500&display=swap');
// basic html overrides
@import 'styles/basics';
// layout
@import 'styles/layout.scss';
// font styling
@import 'styles/typography.scss';

@import 'styles/overrides.scss';

#app {
  top: 0;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  text-align: center;

  color: $off-white;

  height: 100vh;

  background: $black;
}
</style>
