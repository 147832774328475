import axios from 'axios'
import { OPTIMUS } from '@/assets/js/api.js'

const ENDPOINT = 'getModelData/'
const { APIURL } = OPTIMUS
// Generic
export function postGetModelData(Bucket, Key) {
  return axios({
    method: 'post',
    url: APIURL + ENDPOINT,
    data: {
      Bucket,
      Key
    }
  })
}
