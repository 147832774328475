import axios from 'axios'
import { OPTIMUS } from '@/assets/js/api.js'

const { VUEBASEURL } = OPTIMUS

export function postGetNarrativeByID(narrativeID) {
  return axios.post(`${VUEBASEURL}getNarrativeByID`, {
    narrativeID
  })
}
