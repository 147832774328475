/**
 * @import library functions
 */
import {
  MovingAverageCalculator,
  filterHeaders,
  arrayToObject,
  createMetricLegend,
  assignColorBinning
} from '@/assets/js/helper.js'
/**
 * @import chroma color library
 */
import chroma from 'chroma-js'
/**
 * @import d3 functions
 */
import { scaleQuantile, scaleLinear } from 'd3-scale'
import { extent } from 'd3-array'

/**
 *
 * @param {Array} modelData model design space data array of objects data.csv data
 * @param {Object} inputSettings input settings from settings.json
 * @param {Number} startModelID initial default model to start with
 */
export function createInputObject(modelData, inputSettings, startModelID) {
  let message = 'success'

  try {
    const headers = Object.keys(modelData[0]).slice(1)
    const inputHeaders = filterHeaders(headers, 'in')
    const inputSettingsHeaders = Object.keys(inputSettings)
    const firstModel = modelData.filter(e => e.iteration === startModelID)[0]

    const checkInputHeaders = inputHeaders.every(d =>
      inputSettingsHeaders.includes(d)
    )

    /**
     * @test check if input settings match data.csv inputs
     */
    if (!checkInputHeaders) {
      message = 'incorrect input headers'
      throw new Error(message)
    }
    // create input object key/value pairs
    let inputObject = inputHeaders.map(dataName => {
      return {
        dataName: dataName,
        ref: dataName.split('in_')[1],
        label: inputSettings[dataName].label,
        marks: [],
        data: [],
        value: null,
        scaleLinear: scaleLinear()
      }
    })

    // convert to keyed object
    inputObject = arrayToObject(inputObject, 'dataName')

    modelData.forEach(d => {
      inputHeaders.forEach(objectKey => {
        if (!inputObject[objectKey].data.includes(d[objectKey])) {
          inputObject[objectKey].data.push(d[objectKey])
          // sort the input headers each iteration
          inputObject[objectKey].data.sort((a, b) => {
            return a - b
          })
        }
      })
    })

    inputHeaders.forEach(function(objectKey) {
      // init a dictionary
      const marks = {}
      // map values in data to new labels
      // in this setup, all data points have labels
      // might need to rewrite based on larger input datasets
      inputObject[objectKey].data.forEach((d, i) => {
        marks[d] = {
          label: inputSettings[objectKey].labels[i]
        }
      })
      inputObject[objectKey].marks = marks
      // returns first object in data.csv as initial starting place for input sliders
      inputObject[objectKey].value = firstModel[objectKey]
      // inputObject[objectKey].value = inputObject[objectKey].data[0]

      // return inputObject[objectKey]
    })

    // sets up linear scale for input sliders
    inputHeaders.forEach(objectKey => {
      let extentRange = null

      if (isNaN(Object.keys(inputObject[objectKey]['marks'])[0])) {
        extentRange = extent(Object.keys(inputObject[objectKey]['data']))
      } else {
        extentRange = extent(inputObject[objectKey].data, p => {
          return +p
        })
      }
      inputObject[objectKey].scaleLinear.domain(extentRange)
    })

    return [true, message, inputHeaders, inputObject]
  } catch (error) {
    console.error(error)
    return [false, message, null, null]
  }
}

export function createMetricObject(modelData, metricSettings) {
  let message = 'success'

  try {
    const headers = Object.keys(modelData[0]).slice(1)
    const metricHeaders = filterHeaders(headers, 'out')
    const metricKeys = Object.keys(metricSettings)
    const starRatingRange = [0, 0.5, 1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5]

    // const checkMetricHeaders = isEqual(metricHeaders, Object.keys(metricSettings))

    // if (!checkMetricHeaders) {
    //   message = 'incorrect metric headers'
    //   throw new Error(message)
    // }

    let metricObject = metricKeys.map((d, i) => {
      const setting = metricSettings[d]

      // checks if setting exists, if not, sets default value
      const binType = setting.binType === undefined ? 'Linear' : setting.binType
      const binBounds = setting.binBounds === undefined ? [] : setting.binBounds

      const legend = createMetricLegend(
        binType,
        binBounds,
        setting.colorRamp,
        chroma.scale(setting.colorRamp).domain([0, 350])
      )

      const colorScale = assignColorBinning(
        binType,
        binBounds,
        setting.colorRamp,
        [setting.minBound, setting.maxBound]
      )

      return {
        dataName: metricHeaders[i],
        label: setting.label,
        binType,
        binBounds,
        legend,
        data: [],
        toggle: false,
        starRating: 0,
        starRatingArray: [],
        cramp: setting.colorRamp,
        colorRamp: setting.colorRamp,
        minBound: setting.minBound,
        maxBound: setting.maxBound,
        colorScale: colorScale,
        description: setting.description,
        materialType: setting.materialType,
        opacity: setting.opacity,
        scaleQuantile: scaleQuantile(),
        value: null,
        ticks: setting.ticks,
        filterModeTicks:
          setting.filterModeTicks === undefined ? [] : setting.filterModeTicks,
        filterModeRange:
          setting.filterModeRange === undefined ? [] : setting.filterModeRange,
        units: setting.units,
        scaleLinear: scaleLinear(),
        benchmark: false,
        calcAvgClass: new MovingAverageCalculator(),
        analysis_mesh: setting.analysis_mesh,
        zeroScale:
          setting['zeroScale'] === undefined ? false : setting['zeroScale']
      }
    })

    metricObject = arrayToObject(metricObject, 'dataName')

    modelData.forEach(d => {
      metricHeaders.forEach(objectKey => {
        metricObject[objectKey].calcAvgClass.update(d[objectKey])

        if (!metricObject[objectKey].data.includes(d[objectKey])) {
          metricObject[objectKey].data.push(d[objectKey])
        }
      })
    })

    metricHeaders.forEach(objectKey => {
      const metric = metricObject[objectKey]
      metric.scaleQuantile.domain(metric.data).range(starRatingRange)

      /**
       * checks for filter domain range override in filterModeRange
       */
      let domain = []
      if (metric.filterModeRange.length > 0) {
        domain = metric.filterModeRange.map(d => parseFloat(d))
      } else {
        domain = extent(metric.data.map(d => parseFloat(d)))
      }

      metric.scaleLinear.domain(domain)
    })

    return [true, message, metricHeaders, metricObject]
  } catch (error) {
    console.error(error)
    return [false, message, 'error', error]
  }
}
